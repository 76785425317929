<template>
  <div class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center" @click.stop="$emit('close')">

    <div class="modal-container" @click.stop="$emit('dont-close')">

    <div class="flex flex-col px-8">

        <div class="mb-8 flex flex-col items-center text-xl m-auto text-black dark:text-white">
          <div class="text-base">{{ `${album.artist} - ${album.title}` }}<span v-if="album.year">{{ ` (${album.year})` }}</span></div>
          <div v-if="showPlayButton" class="mt-2">
            <div class="w-6 h-6 flex items-center">
              <button @click.stop="$emit('play-clicked')">
                <svg
                  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px"
                  height="20px" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
                  <g fill="currentColor">
                    <polygon points="1.5,1.006 1.5,18.993 18.5,10" />
                  </g>
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div v-if="!showFFMLinks">
          <div
            v-if="showAppleMusicLink || album.spotify_id || album.tidal_id || album.amazon_music_url"
            class="flex flex-col mb-8">
            <div class="font-extrabold mb-2">Open in</div>
            <a  v-if="showAppleMusicLink"
                :href="`https://music.apple.com/${album.locale}/album/${album.apple_music_id}`" target="_blank"
                class="modal-link">Apple Music</a>
            <a  v-if="album.spotify_id"
                :href="`https://open.spotify.com/album/${album.spotify_id}`" target="_blank"
                class="modal-link">Spotify</a>
            <a  v-if="album.tidal_id"
                :href="`https://listen.tidal.com/album/${album.tidal_id}`" target="_blank"
                class="modal-link">TIDAL HiFi</a>
            <a  v-if="album.amazon_music_url"
                :href="album.amazon_music_url"
                target="_blank"
                class="modal-link">Amazon Music</a>
          </div>

          <div class="mb-8 flex flex-col" >
            <a  v-if="album.is_nft"
                :href="album.nft_metadata.url"
                target="_blank"
                class="modal-link">View NFT on {{ album.nft_metadata.platform.charAt(0).toUpperCase() + album.nft_metadata.platform.slice(1) }}</a>
            <a  v-if="showNftLink"
                href="https://beta.catalog.works/teendaze"
                target="_blank"
                class="modal-link">View NFTs on Catalog</a>
            <a  v-for="(link, index) in album.links" :key="index"
                :href="link.url"
                target="_blank"
                class="modal-link">{{ link.label }}</a>
          </div>

          <div class="mb-8 flex flex-col" v-if="showBuyLinks">
            <div class="font-extrabold mb-2">Buy on</div>
            <a  v-if="album.bandcamp_url"
                :href="album.bandcamp_url"
                target="_blank"
                class="modal-link">Bandcamp</a>
          </div>

          <div class="mb-8 flex flex-col" v-if="album.supercollector_url">
            <div class="font-extrabold mb-2">Collect on</div>
            <a  :href="album.supercollector_url"
                target="_blank"
                class="modal-link">Supercollector</a>
          </div>

          <div v-if="!album.is_nft" class="text-xs opacity-90">Links provided by our friends at Odesli</div>
        </div>
        <div v-else>
          <div class="flex flex-col mb-8">
            <a
              href="https://blackmidi.ffm.to/hellfire.UED"
              target="_blank"
              class="modal-link">Links on Feature FM</a>
          </div>
        </div>

        <button
          class="modal-close"
          @click.stop="$emit('close')">Close</button>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoModal',
  props: {
    album: null
  },
  computed: {
    showFFMLinks(){
      return this.album.id === 'b49d4827-dfc9-40cd-a345-e076c77d53a8'
    },
    truncatedTitle(){
      return this.album.title
    },
    showPlayButton(){
      return (this.appleMusicConnected && this.album.apple_music_id) ||
        (this.spotifyConnected && this.album.spotify_id) ||
        (this.tidalConnected && this.album.tidal_id) ||
        this.notConnected || this.album.is_nft
    },
    notConnected(){
      return !this.appleMusicConnected && !this.tidalConnected && !this.spotifyConnected
    },
    appleMusicConnected(){
      return this.$store.getters['musickit/connected']
    },
    tidalConnected(){
      return this.$store.getters['tidal/connected']
    },
    spotifyConnected(){
      return this.$store.getters['spotify/connected']
    },
    showAppleMusicLink(){
      if(this.album.apple_music_id){
        return true
      }else{
        return false
      }
    },
    showBandcampLink(){
      if(this.album.bandcamp_id){
        return true
      }
      return false
    },
    showNftLink(){
      if(this.album.artist === 'Teen Daze'){
        return true
      }
      return false
    },
    bandcampTitle(){
      if(this.album.artist === 'Teen Daze'){
        return 'Buy on Bandcamp'
      }
      return 'Buy'
    },
    showBuyLinks(){
      return this.album.bandcamp_url
    }
  },
  methods: {
    play(){
      if(this.$store.state.tidal.track){
        this.$refs.tidalPlayer.play()
      }
    },
    pause(){
      this.$refs.tidalPlayer.pause()
    },
  },
}
</script>